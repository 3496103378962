import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import aboutImg from "../images/about-image.jpg"
import HeaderLogo from "../images/pipp-header-logo.svg"
import AboutHeaderSvg from "../images/ABOUT.svg"
import { Link } from "gatsby"

const ListLink = (props) => (
  <li className="header-list-element">
    <Link to={props.to} className="header-list-link">
      {props.children}
    </Link>
  </li>
);

export default () => (
  <Layout>
    <SEO title="About" />
    <main className="about-main">
      <header className="nav-header">
        <nav>
          <Link className="logo-link" to="/">
            <img src={HeaderLogo} alt="Home" />
          </Link>
          <div className="heading-container about-heading-container">
            <ul className="header-ul">
              <ListLink to="/bio">about</ListLink>
              <ListLink to="/manifesto">manifesto</ListLink>
              <ListLink to="/upcoming">upcoming</ListLink>
              <ListLink to="/past">past</ListLink>
              <ListLink to="/resources">resources</ListLink>
              <ListLink to="/contact">contact</ListLink>
            </ul>
            <img src={AboutHeaderSvg} alt="About" />
          </div>
        </nav>
      </header>
      <div className="two-column-main">
        <div className="side-container">
          <img className="side-image about-image" src={aboutImg} alt="" />
        </div>
        <div className="side-container">
          <article>
            <p>
              Patternmaking in Public Places is a NYC-based project seeking to
              democratize access to patternmaking by removing barriers of
              geography, economics, age, language, and intimidation. PIPP
              believes that patterns are the shapes of our bodies and our
              dreams, and they should belong to all of us.
            </p>
            <br />
            <p>
              To this aim, during the summer of 2019, PIPP is hosting free
              public <Link to="/upcoming">patternmaking demonstrations</Link> at
              parks in all five boroughs of NYC. Aspiring enthusiasts and
              unsuspecting passersby are invited to attend, watch, ask
              questions, and gather new ideas to take home.
            </p>
            <br />
            <p>
              PIPP was started by Louisa Owen Sonstroem, a maker and educator,
              who works at the intersection of clothing, sustainability, and
              individual empowerment. Learn more{" "}
              <a href="https://louisamerry.com">here.</a>
            </p>
          </article>
        </div>
      </div>
    </main>
  </Layout>
);
